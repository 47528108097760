document.addEventListener('DOMContentLoaded', (): void => {
  const el = document.querySelector('a[data-action="show-tos"]')
  if (el instanceof HTMLElement) {
    el.addEventListener('click', function (e) {
      e.preventDefault();

      const url = el.dataset.url
      window.open(url, '', 'width=800,height=800,scrollbars=yes')
    })
  }
})
